.inline{
    display: inline;
  }
  
  .logo {
    font-size: -webkit-xxx-large;
    font-weight: 900;
  }
  
  
  .lens{
    height: 20px;
    position: relative;
    top: -4px;
    left: 0px
  px
  ;
  }
  
  .content{
    background-color: #fff;  
  }
  
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 9999;
    top: 0;
    background-color: #fff;  
    padding-bottom: 3%;
  }
  
  ul {
    list-style-type: none;
    overflow: hidden;
  }
  
  li {
    float: right;
  }
  
  li a {
    color: #000;
    display: block;
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    font-size:14px;
  }
  li a:hover {
    text-decoration: underline;
  }
  .grid{
    height:23px;
    position:relative;
    bottom:4px;
  }
  .signbutton{
    background-color: #4885ed;
    color: #fff;
    border:none;
    border-radius:3px;
    padding:7px 10px;
    position:relative;
    bottom:7px;
    font-weight:bold;
  }
  
  .bar{
    margin:0 auto;
    width:575px;
    border-radius:30px;
    border:1px solid #dcdcdc;
  }
  .bar:hover{
    box-shadow: 1px 1px 8px 1px #dcdcdc;
  }
  .bar:focus-within{
    box-shadow: 1px 1px 8px 1px #dcdcdc;
    outline:none;
  }
  .searchbar{
    height:45px;
    border:none;
    width:500px;
    font-size:16px;
    outline: none;
    
  }
  .voice{
    height:20px;
    position:relative;
    top: -3px;
    left:10px;
  }
  .buttons{
    margin-top:30px;
  }
  .button{
    background-color: #f5f5f5;
    border:none;
    color:#707070;
    font-size:15px;
    padding: 10px 20px;
    margin:5px;
    border-radius:4px;
    outline:none;
  }
  .button:hover{
    border: 1px solid #c8c8c8;
    padding: 9px 19px;
    color:#808080;
  }
  .button:focus{
    border:1px solid #4885ed;
    padding: 9px 19px;
  }
  
  
  
  /* .con{
    padding-top: 10%
  }
  
  .nv {
    width: 100%;
    height: 14%;
    margin-bottom: 5%;
    margin-top: -1%;
  }
  
  .navbar-brand
    {
      display: block;
      left: 40%;
      position: absolute;
      text-align: center;
      z-index: 1030;
      font-size: xx-large;
      font-weight: 900;
      font-family: sans-serif;
    }
  
  .search{
    margin-top: 5%;  
    z-index: 1030;
  }
  
  ul {
    list-style-type: none;
    overflow: hidden;
  }
  
  li {
    float: right;
  }
  
  li a {
    color: #000;
    display: block;
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    font-size:14px;
  }
  li a:hover {
    text-decoration: underline;
  }
  .grid{
    height:23px;
    position:relative;
    bottom:4px;
  }
  .signbutton{
    background-color: #4885ed;
    color: #fff;
    border:none;
    border-radius:3px;
    padding:7px 10px;
    position:relative;
    bottom:7px;
    font-weight:bold;
  }
  
  .bar{
    margin:0 auto;
    width:575px;
    border-radius:30px;
    border:3px solid #000;
  }
  .bar:hover{
    box-shadow: 1px 1px 8px 1px #dcdcdc;
  }
  .bar:focus-within{
    box-shadow: 1px 1px 8px 1px #dcdcdc;
    outline:none;
  }
  .searchbar{
    height: 40px;
    border: none;
    margin: 0px 0px 0px 24px;
    width: 500px;
    font-size: 16px;
    outline: none;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
  
    
  }
  .voice{
    height:20px;
    position:relative;
    top:0px;
    left:10px;
  }
  .buttons{
    margin-top:30px;
  }
  .button{
    background-color: #f5f5f5;
    border:none;
    color:#707070;
    font-size:15px;
    padding: 10px 20px;
    margin:5px;
    border-radius:4px;
    outline:none;
  }
  .button:hover{
    border: 1px solid #c8c8c8;
    padding: 9px 19px;
    color:#808080;
  }
  .button:focus{
    border:1px solid #4885ed;
    padding: 9px 19px;
  }
   */
  
  .w3-badge, .w3-tag {
    background-color: darkgreen;
    color: #fff;
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    border: 2px solid #000;
    border-radius: 30px;
    margin: 2%;
    font-family: cursive;
  }
  
  
  .videoContainer {
    position: relative;
  }
  .playBtn {
    position: absolute;
    width: 96px;
    height: 96px;
    left: 50%;
    top: 50%;
    margin-left: -48px; /*half of the width */
    margin-top: -48px; /*half of the height */
  }
  
  .subtitle{
    margin-left: 40.5%;
    margin-top: 5%;
    font-family: cursive;
    white-space: pre;
  }
  
  
  @media only screen and (max-width: 600px) {
   
    .navbar-brand {
      left: 20%;
    }
  
    .search {
      margin-top: 25%;
    }
  
    .subtitle {
      margin-left: 20.5%;
      margin-top: 18%;
    }
  
    .bar {
      margin: 1px auto;
      width: 326px;
    }
  
    .searchbar {
      height: 17px;
      margin-top: 2%;
    }
  
    .voice{
      height: 21px;
      position: relative;
      top: -10px;
      left: 277px;
    }
  
  .mobDown{
    margin-top: 33%;
  }
   
  }
  

  .drop{
    width: 200px !important;
  }

  .dropcontainer {
    margin-top: 8%;
}

label{
    margin-right: 4%;
    margin-left : 4%;
}

.buttoncontainer {
    padding-right: 35%;
    padding-left: 35%;
    margin-left: 6%;
    margin-top: 10%;
}

a:hover {
    color:black; 
    text-decoration:none !important; 
    cursor:pointer;  
}

a {
    color:black; 
    text-decoration:none !important; 
    cursor:pointer;  
}